import React, { useEffect, useState } from 'react';
import { CookieModal } from '@schlomoh/react-cookieConsent';

const Matomo = () => {
    const [showBanner, setShowBanner] = useState(false);

    const containerStyle = { overflow: 'hidden' };
    const buttonStyle = { borderRadius: 20 };
    const primaryButtonStyle = { ...buttonStyle, backgroundColor: '#59bd65', fontSize: 12 };
    const secondaryButtonStyle = { ...buttonStyle, fontSize: 12 };

    const ManagementContent = () => (
        <>
            <h4 style={{ color: "black" }}>Cookie Einstellungen</h4>
            <p style={{ fontSize: 12 }}>Für die Verarbeitung Ihrer Daten für diese Kategorien benötigen wir Ihre Einwilligung, um Ihnen das bestmögliche Nutzungserlebnis zu ermöglichen.</p>
            <p style={{ fontSize: 12 }}>Bei der Analyse kommt der Dienst von Matomo zum Einsatz.</p>
        </>
    );

    useEffect(() => {
        // Überprüfen, ob der Cookie gesetzt wurde
        const analyticsCookieValue = document.cookie
            .split('; ')
            .find((row) => row.startsWith('selection='))
            ?.split('=')[1];

        if (analyticsCookieValue !== '{%22Analyse%22:true}') {
            setShowBanner(true);
        }
    }, []);

    const onAccept = (cookies) => {
        if (cookies.Analyse) {
            var _mtm = window._mtm = window._mtm || [];
            _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true; g.src = 'https://matomo.simplify-data.de/js/container_hzi6HnXv.js'; s.parentNode.insertBefore(g, s);
        }
        setShowBanner(false); // Banner schließen nach der Zustimmung
    };

    const onDecline = () => {
        setShowBanner(false); // Banner schließen nach Ablehnung
    };

    const Content = () => (
        <>
            <img style={{ position: 'absolute', marginTop: -20, width: 64, left: '50%', transform: 'translate(-50%, -50%)' }} src="/images/cookie.svg" alt="Stars" loading="lazy" />
            <h4 style={{ color: "black" }}>Willkommen bei Simplify Data</h4>
            <p style={{ fontSize: 12 }}>Wir nutzen auf unseren Webseiten Cookies und Trackingtechnologien. Diese dienen der Optimierung unserer Website, der Weiterentwicklung von Services und Marketingzwecken. Der Einsatz bestimmter Cookies ist für die uneingeschränkte Nutzung unserer Website technisch erforderlich.</p>
            <p style={{ fontSize: 12 }}>Durch Klick auf "Zustimmen und weiter" stimmen Sie zu, dass auch Cookies zu Analysezwecken gesetzt werden.</p>
            <p style={{ fontSize: 12 }}>Die Einwilligung können Sie jederzeit widerrufen. Weitere Informationen sowie die Widerspruchsmöglichkeit finden Sie in unserer Datenschutzinformation.</p>
        </>
    );

    return (
        <>
            {showBanner && (
                <div className="cookie-banner show">
                    <CookieModal
                        acceptButtonText='Zustimmen und weiter'
                        declineButtonText='Ich lehne alles ab'
                        headingColor='white'
                        paragraphColor='grey'
                        containerStyle={containerStyle}
                        primaryButtonStyle={primaryButtonStyle}
                        secondaryButtonStyle={secondaryButtonStyle}
                        infoContent={<Content />}
                        managementContent={<ManagementContent />}
                        enableManagement
                        onAccept={onAccept}
                        onDecline={onDecline}
                        managementButtonText='Einstellungen'
                        cookieCategories={['Analyse']}
                    />
                </div>
            )}
        </>
    );
};

export default Matomo;
